import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SiteOverviewEffects } from './site-overview-state/site-overview.effects';
import { SiteOverviewFacade } from './site-overview-state/site-overview.facade';
import * as fromSiteOverview from './site-overview-state/site-overview.reducer';

@NgModule({
  imports: [
    CommonModule,

    StoreModule.forFeature(
      fromSiteOverview.SITE_OVERVIEW_FEATURE_KEY,
      fromSiteOverview.siteOverviewReducer
    ),
    EffectsModule.forFeature([SiteOverviewEffects]),
  ],
  providers: [
    SiteOverviewFacade
  ],
})
export class DataAccessSitesModule {}
