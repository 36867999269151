import {ISite, SortByType, SortDirectionType} from '@mysas/shared/data-access-sites';
import {createAction, props} from '@ngrx/store';

export const initSites = createAction('[Sites Page] Init');

export const searchSites = createAction(
  '[Sites/API] Search Sites',
  props<{ query: string }>()
);

export const loadSitesSuccess = createAction(
  '[Sites/API] Load Sites Success',
  props<{ sites: ISite[] }>()
);

export const loadSitesFailure = createAction(
  '[Sites/API] Load Sites Failure',
  props<{ error: string }>()
);

export const changeSortBy = createAction(
  '[Sites Page] Sort By Changed',
  props<{ sortBy: SortByType }>()
);

export const toggleSortDirection = createAction(
  '[Sites List] Toggle Sort Direction'
);

export const changeSortDirection = createAction(
  '[Sites Page] Sort Direction Changed',
  props<{ sortDirection: SortDirectionType }>()
);

export const changeFilter = createAction(
  '[Sites Page] Filter Changed',
  props<{ filter: string | null }>()
);
