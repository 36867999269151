import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { PortalDashboardShellModule } from '@mysas/portal/dashboard/shell';
import { PortalFallbackPagesShellModule } from '@mysas/portal/fallback-pages/shell';
import { PortalOrdersShellModule } from '@mysas/portal/orders/shell';
import { DataAccessNotificationsModule } from '@mysas/portal/shared/data-access-notifications';
import { PortalSharedDataAccessOrdersModule } from '@mysas/portal/shared/data-access-orders';
import { OutageAlertService } from '@mysas/portal/shared/data-access-outages';
import { PortalSharedDataAccessSitesModule } from '@mysas/portal/shared/data-access-sites';
import { PortalSharedDataAccessUserModule } from '@mysas/portal/shared/data-access-user';
import { ChatModule } from '@mysas/portal/shared/ui-chat';
import {
  AlertComponentModule,
  BannerComponentModule,
  LeftNavComponentModule,
  MessageToastModule
} from '@mysas/portal/shared/ui-layout';
import { OutageAlertComponent } from '@mysas/portal/shared/ui-layout/outage-alert';
import { PortalSitesShellModule } from '@mysas/portal/sites/shell';
import { AccessTokenInterceptor } from '@mysas/shared/ui-data-access-auth';
import { environment } from '@mysas/shared/util-environment';
import { i18nConfig, TranslocoRootModule } from '@mysas/shared/util-i18n';
import {
  TranslocoConfig,
  TranslocoService,
  TRANSLOCO_CONFIG
} from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule
} from 'ngx-google-analytics';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { firstValueFrom } from 'rxjs';
import { AppComponent } from './app.component';
import { PortalLogLevel } from './portal.log-level';

/**
 * This is a list of all Shell modules which in turn define and control their
 * domain's feature routes. These modules are specific in a SPECIFIC ORDER such that
 * their routes are loaded in order.
 *
 * FallbackPages should always be at the end since it contains the catch-all `path: '**'`
 * route for the 404 page.
 *
 * Note that handling old links to redirect to the new link is maintained in NotFoundComponent#ngOnInit
 *
 * @type {Type<NgModule>[]}
 */
const ShellModules: Type<NgModule>[] = [
  PortalDashboardShellModule,
  PortalOrdersShellModule,
  PortalSitesShellModule,
  PortalFallbackPagesShellModule,
];

/**
 * Google Analytics should only ever be turned on in prod. These modules have been abstracted
 * to this array for further customization (if necessary) without cluttering up AppModule's imports
 * more than necessary.
 *
 * See https://github.com/maxandriani/ngx-google-analytics for more options and configuration
 */
const GoogleAnalyticsModules = environment.production
  ? [
      NgxGoogleAnalyticsModule.forRoot(environment.gaTrackingCode),
      NgxGoogleAnalyticsRouterModule,
    ]
  : [];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    OktaAuthModule,
    RouterModule.forRoot([], {
      initialNavigation: 'enabledBlocking',
      enableTracing: false,
      anchorScrolling: 'enabled',
    }),
    FormsModule,
    ReactiveFormsModule,
    TranslocoRootModule,
    HttpClientModule,
    LoggerModule.forRoot({
      level:
        PortalLogLevel[environment.environmentName] ?? NgxLoggerLevel.ERROR,
    }),
    LeftNavComponentModule,
    BannerComponentModule,
    AlertComponentModule,
    OutageAlertComponent,
    StoreModule.forRoot(
      {
        router: routerReducer,
      },
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          // these should be set to true - BUT without these set to false,
          // actions do not allow components to be passed via actions
          // https://gitter.im/ngrx/platform?at=5f3cbaa33dac53434017de6a
          strictActionImmutability: false,
          strictActionSerializability: false,
          strictActionTypeUniqueness: true,
          strictActionWithinNgZone: true,
          strictStateImmutability: true,
          strictStateSerializability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreRouterConnectingModule.forRoot({
      // stateKey: 'router',
      // serializer: CustomSerializer,
    }),
    PortalSharedDataAccessOrdersModule,
    PortalSharedDataAccessSitesModule,
    DataAccessNotificationsModule,
    PortalSharedDataAccessUserModule,
    ChatModule,
    MessageToastModule,
    ...ShellModules,
    ...GoogleAnalyticsModules,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (transloco: TranslocoService) => {
        return () => firstValueFrom(transloco.load(transloco.getActiveLang()));
      },
      deps: [TranslocoService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (alertService: OutageAlertService) => {
        return () => alertService.initialize();
      },
      deps: [OutageAlertService],
      multi: true,
    },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        ...i18nConfig,
        prodMode: environment.production,
      } as TranslocoConfig,
    },
    {
      provide: OKTA_CONFIG,
      useFactory: () => {
        const oktaAuth = new OktaAuth(environment.oidc);

        return {
          oktaAuth,
          onAuthRequired: (oktaAuth: OktaAuth, injector: Injector) => {
            console.log(`onAuthRequired! Redirecting...`);
            const router = injector.get(Router);
            // Redirect the user to your custom login page
            router.navigate(['/login/redirect']);
          },
        };
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private router: Router) {
    // console.log(`api endpoint: ${environment.apiConnectorEndpoint}`);
    // console.log(router.config);
  }
}
