import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {routerNavigatedAction} from '@ngrx/router-store';
import {catchError, filter, map, Observable, of, switchMap} from 'rxjs';

import * as SiteOverviewActions from './site-overview.actions';
import {SitesService} from "@mysas/portal/shared/data-access-sites";

@Injectable()
export class SiteOverviewEffects {



  /**
   * This observable watches the router and uses the site number from the URL
   * to trigger a loading of many other resources:
   *
   *  │Page Loads with site number in URL
   *  │
   *  └► Load Site Details
   *  │
   *  │► Load Site Cadences
   *  │
   *  └► Load Site Download History
   */
  siteNumber$: Observable<string> = this.actions$.pipe(
    ofType(routerNavigatedAction),
    filter(({ payload }) => payload.event.url.startsWith('/sites/')),
    map(({ payload }) => {
      // due to lazy loading, this URL param is buried deep in the routerState
      const tssite =
        payload.routerState.root.firstChild?.firstChild?.params['tssite'];
      return tssite as string;
    })
  );

  /**
   * This effect watches for the `siteNumber$` observable to emit, which implies that
   * the browser navigated to a URL such as /sites/<tssite>. This in turn triggers the loading
   * of an API call.
   */
  triggerSiteDetails$ = createEffect(() => {
    return this.siteNumber$.pipe(
      map((tssite) => SiteOverviewActions.LoadSiteDetail.load({ tssite }))
    );
  });

  loadSiteDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SiteOverviewActions.LoadSiteDetail.load),
      switchMap(({ tssite }) => {
        return this.sitesService.getSiteDetails(tssite).pipe(
          map(( data ) => {
            return SiteOverviewActions.LoadSiteDetail.success({
              detail: data,
            });
          }),
          catchError((err: Error) =>
            of(
              SiteOverviewActions.LoadSiteDetail.failure({
                error: err.message,
              })
            )
          )
        );
      })
    );
  });

  requestLicenseExtension$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SiteOverviewActions.LicenseExtension.request),
      switchMap(({ tssite, releaseGroup }) => {
        return this.sitesService.requestLicenseExtension(tssite, releaseGroup).pipe(
          map(( license ) => {
            return SiteOverviewActions.LicenseExtension.success({
              license: license,
            });
          }),
          catchError((err: Error) =>
            of(
              SiteOverviewActions.LicenseExtension.failure({
                error: err.message,
              })
            )
          )
        );
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private sitesService: SitesService
  ) {}

}
