import {ISite, SortByType, SortDirectionType} from '@mysas/shared/data-access-sites';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, ActionCreator, createReducer, Creator, on, ReducerTypes} from '@ngrx/store';

import * as SitesActions from './sites.actions';

export const SITES_FEATURE_KEY = 'sites';

export interface SitesState extends EntityState<ISite> {
  selectedId?: string | number; // which Sites record has been selected
  loaded: boolean; // has the Sites list been loaded
  error?: string | null; // last known error (if any)
  sortBy: SortByType;
  sortDirection: SortDirectionType;
  filter?: string | null;
}

export interface SitesPartialState {
  readonly [SITES_FEATURE_KEY]: SitesState;
}

export const sitesAdapter: EntityAdapter<ISite> = createEntityAdapter<ISite>(
  {
    selectId: (site: ISite) => site.tssite,
  }
);

export const initialSitesState: SitesState = sitesAdapter.getInitialState({
  // set initial required properties
  loaded: true,
  sortBy: 'site',
  sortDirection: 'descending',
  filter: null,
});

const sortAndFilterActions: ReducerTypes<
  SitesState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly ActionCreator<string, Creator<any[], object>>[]
>[] = [
  on(
    SitesActions.toggleSortDirection,
    (state): SitesState => ({
      ...state,
      sortDirection:
        state.sortDirection === 'ascending' ? 'descending' : 'ascending',
    })
  ),
  on(
    SitesActions.changeSortBy,
    (state, { sortBy }): SitesState => ({
      ...state,
      sortBy,
    })
  ),
  on(
    SitesActions.changeSortDirection,
    (state, { sortDirection }): SitesState => ({
      ...state,
      sortDirection,
    })
  ),
  on(
    SitesActions.changeFilter,
    (state, { filter }): SitesState => ({
      ...state,
      filter,
    })
  ),
];

const reducer = createReducer(
  initialSitesState,
  on(
    SitesActions.initSites,
    (state): SitesState => ({
      ...state,
      loaded: false,
      error: null,
    })
  ),
  on(
    SitesActions.searchSites,
    (state): SitesState => ({
      ...state,
      loaded: false,
      error: null,
    })
  ),
  on(
    SitesActions.loadSitesSuccess,
    (state, { sites }): SitesState =>
      sitesAdapter.setAll(sites, { ...state, loaded: true })
  ),
  on(
    SitesActions.loadSitesFailure,
    (state, { error }): SitesState => ({
      ...state,
      loaded: true,
      error,
    })
  ),

  ...sortAndFilterActions
);

export function sitesReducer(state: SitesState | undefined, action: Action) {
  return reducer(state, action);
}
