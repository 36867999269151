import {
  ICadence,
  IOrderAvailableCadence,
  IOrderDetail
} from '@mysas/shared/data-access-orders';
import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrderOverviewState,
  ORDER_OVERVIEW_FEATURE_KEY
} from './order-overview.reducer';

export const selectRouter = createFeatureSelector<RouterReducerState>(
  ORDER_OVERVIEW_FEATURE_KEY
);

// Lookup the 'OrderOverview' feature state managed by NgRx
export const getOrderOverviewState = createFeatureSelector<OrderOverviewState>(
  ORDER_OVERVIEW_FEATURE_KEY
);

export const getOrderDetails = createSelector(
  getOrderOverviewState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ loaded, error, users, ...rest }): Partial<IOrderDetail> | undefined => rest
);

export const getOrderOverviewLoaded = createSelector(
  getOrderOverviewState,
  (state: OrderOverviewState) => state.loaded
);

export const getOrderOverviewError = createSelector(
  getOrderOverviewState,
  (state: OrderOverviewState) => state.error
);

export const getOrderUsers = createSelector(
  getOrderOverviewState,
  ({ users }) => users
);

export const getAddUserRequestState = createSelector(
  getOrderOverviewState,
  ({ addUserRequest }) => addUserRequest
);

export const getUpdateUserPermissionsState = createSelector(
  getOrderOverviewState,
  ({ updateUserPermissionRequest }) => updateUserPermissionRequest.processing
);

export const getOrderAvailableCadences = createSelector(
  getOrderOverviewState,
  ({ availableCadences }) => availableCadences
);

export const getCadenceVersions = createSelector(
  getOrderOverviewState,
  ({ availableCadences }) =>
    availableCadences.reduce(
      (acc: ICadence[], curr: IOrderAvailableCadence) =>
        acc.concat(curr.versions),
      []
    )
);

export const getDownloadHistory = createSelector(
  getOrderOverviewState,
  ({ downloadHistory }) => downloadHistory
);

export const getAssetPanelState = createSelector(
  getOrderOverviewState,
  ({ assetPanel }) => assetPanel
);

export const getDeploymentAssetPanelState = createSelector(
  getOrderOverviewState,
  ({ deploymentAssetPanel }) => deploymentAssetPanel
);

export const getAvailableDeploymentAssets = createSelector(
  getOrderOverviewState,
  ({ availableDeploymentAssets }) => availableDeploymentAssets
);

export const getSelectedDeploymentAssets = createSelector(
  getOrderOverviewState,
  ({ selectedDeploymentAssets }) => selectedDeploymentAssets
);

export const getOrderOnHold = createSelector(
  getOrderOverviewState,
  ({ onHold }) => onHold
);

export const getAssetDownloadInProgress = createSelector(
  getOrderOverviewState,
  ({ assetDownloadInProgress }) => assetDownloadInProgress
);

export const selectAvailableCadenceNames = createSelector(
  getOrderOverviewState,
  ({ availableCadences }) =>
    availableCadences.reduce((acc: string[], curr) => {
      if (!acc.includes(curr.cadenceName)) {
        acc.push(curr.cadenceName);
      }
      return acc;
    }, [])
);

export const selectAvailableVersionNames = createSelector(
  getOrderOverviewState,
  ({ availableCadences }) =>
    availableCadences.reduce((acc: string[], curr) => {
      curr.versions.forEach((v) => {
        if (!acc.includes(v.cadenceVersion)) {
          acc.push(v.cadenceVersion);
        }
      });
      return acc;
    }, [])
);

export const selectDownloadsFilter = createSelector(
  getOrderOverviewState,
  ({ filters }) => filters.filter
);

export const selectFiltersState = createSelector(
  getOrderOverviewState,
  ({ filters }) => filters
);

/** Loading-related selectors */

export const selectLoadingState = createSelector(
  getOrderOverviewState,
  ({ loading }) => loading
);

export const selectLoadingStateOrderDetails = createSelector(
  selectLoadingState,
  ({ details }) => details
);

export const selectLoadingStateOrderUsers = createSelector(
  selectLoadingState,
  ({ users }) => users
);

export const selectLoadingStateOrderCadences = createSelector(
  selectLoadingState,
  ({ cadences }) => cadences
);

export const selectLoadingStateOrderDeploymentAssets = createSelector(
  selectLoadingState,
  ({ deploymentAssets }) => deploymentAssets
);

export const selectLoadingStateOrderHistory = createSelector(
  selectLoadingState,
  ({ history }) => history
);

// export const selectLoadingStateOf = createSelector(
//   getOrderOverviewState,
//   (
//     state: OrderOverviewState,
//     key: 'details' | 'users' | 'cadences' | 'history'
//   ) => state.loading[key]
// );

// export const selectAllLoaded = createSelector(selectLoadingState, (loading) =>
//   Object.values(loading).every((s) => s === 'LOADED')
// );
