import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SitesEffects } from './sites-state/sites.effects';
import { SitesFacade } from './sites-state/sites.facade';
import * as fromSites from './sites-state/sites.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromSites.SITES_FEATURE_KEY,
      fromSites.sitesReducer
    ),
    EffectsModule.forFeature([SitesEffects])
  ],
  providers: [SitesFacade],
})
export class PortalSharedDataAccessSitesModule {}
