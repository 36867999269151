import {ISite} from './interfaces';

export type SortDirectionType = 'ascending' | 'descending';

export type SortByType = 'site' | 'family';

/**
 * Util function for sorting an array of sites when using the `.sort()` method.
 *
 * @example
 * ```
 * siteDetailList.sort((a,b) => sortSites(a, b, 'site', 'descending'))
 * ```
 *
 * @param {ISiteDetail} a
 * @param {ISiteDetail} b
 * @param {SortByType} sortBy
 * @param {SortDirectionType} sortDir
 * @returns {number}
 */
export const sortSites = (
  a: ISite,
  b: ISite,
  sortBy: SortByType,
  sortDir: SortDirectionType
): number => {
  let rv: number;
  switch (sortBy) {
    case 'family':
      rv = a.family.localeCompare(b.family);
      break;
    case 'site':
      rv = a.tssite.localeCompare(b.tssite);
      break;
  }
  if (sortDir === 'descending') {
    return -1 * rv;
  }
  return rv;
};
