<ng-container *transloco="let t; read: 'orderOverview'">
  <div class="row" *ngIf="version">
    <div class="col">
      <h2 tabindex="0" id="download-cadence-version-name-header">
        {{ version.cadenceName }} - {{ t('downloadAssetsVersion.label') }}
        {{ version.cadenceVersion }}
        <!-- <span *ngIf="currentCadence.id === version.id"
        >&nbsp;(Selected Cadence)</span
      > -->
      </h2>
    </div>

    <div class="col d-flex justify-content-end">
      <button
        tabindex="0"
        class="btn btn-standard-no-bd detail-btn"
        (click)="openPanel()"
      >
        {{ t('downloadAssetsDetails.label') }}
      </button>
      <button
        tabindex="0"
        class="btn btn-standard-no-bd download-btn"
        style="margin-inline-start: var(--space-small)"
        [disabled]="!anyChecked || disableDownloadBtn"
        (click)="triggerDownloads()"
      >
        {{
          downloadInProgress
            ? t('downloadAssetsDownloadBtnActive.label')
            : t('downloadAssetsDownloadBtn.label')
        }}
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col cadence-doc-link-container">
      <a
        tabindex="0"
        [href]="deployDoc"
        aria-describedby="download-cadence-version-name-header"
        target="_blank"
        class="version-link btn btn-lightweight"
        style="padding-inline-start: 0"
        *ngIf="deployDoc"
        >{{ t('downloadAssetsHowToDeploy.link') }}</a
      >

      <a
        tabindex="0"
        aria-describedby="download-cadence-version-name-header"
        [href]="releaseNoteDoc"
        target="_blank"
        class="version-link btn btn-lightweight"
        *ngIf="releaseNoteDoc"
        >{{ t('downloadAssetsReleaseNotes.link') }}</a
      >

      <button
        tabindex="0"
        class="version-link btn btn-lightweight readme-btn"
        aria-describedby="download-cadence-version-name-header"
        (click)="triggerReadmeDownload()"
        *ngIf="readmeDoc"
      >
        <svg
          localeOverrides="{}"
          xmlns="http://www.w3.org/2000/svg"
          class="Download"
          width="14"
          height="14"
          version="1.1"
          viewBox="0 0 14 14"
          xml:space="preserve"
        >
          <path class="svgToolTipPane" fill="none" d="M0 0H14V14H0z"></path>
          <path
            class="iconColor"
            d="M13,11v2c0,0.552-0.448,1-1,1H2c-0.552,0-1-0.448-1-1v-2c0-0.552,0.448-1,1-1v3h10v-3 C12.552,10,13,10.448,13,11z M8,8V1c0-0.552-0.448-1-1-1C6.448,0,6,0.448,6,1v7H3l4,4l4-4H8z"
          ></path>
        </svg>
        <span tabindex="0">{{ t('downloadAssetsReadme.link') }}</span>
      </button>
    </div>
  </div>

  <div class="row" style="margin-block-end: 3rem">
    <div class="col">
      <table class="table table--hover">
        <colgroup>
          <col span="1" style="width: 1%" />
          <col span="1" style="width: 50%" />
          <col span="1" style="width: 20%" />
          <col span="1" style="width: 20%" />
        </colgroup>
        <thead>
          <tr>
            <th>
              <input
                tabindex="0"
                type="checkbox"
                [(ngModel)]="masterSelected"
                (change)="toggleCheckAll()"
                attr.aria-label="{{ t('downloadAssetsSelectAll.aria') }}"
              />
            </th>
            <th tabindex="0" scope="col">
              {{ t('downloadAssetsAssetType.label') }}
            </th>
            <!-- <th>Version</th>
          <th>Cadence</th> -->
            <th scope="col">
              {{ t('downloadAssetsDateAvailable.label') }}
            </th>
            <th scope="col">
              {{ t('downloadAssetsStatus.label') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let dl of checkList; let idx = index"
            (click)="toggleCheck(idx)"
          >
            <td style="vertical-align: middle; text-align: center">
              <input
                tabindex="0"
                class="asset-checkbox"
                type="checkbox"
                name="{{ dl.value.id }}_checkbox"
                value="{{ dl.id }}"
                [(ngModel)]="dl.isSelected"
                (change)="isAllSelected()"
                attr.aria-label="{{
                  t('downloadAssetsSelectAsset.aria', {
                    asset: dl.value.label,
                    order:
                      version?.cadenceName +
                      '-' +
                      t('downloadAssetsVersion.label') +
                      ' ' +
                      version?.cadenceVersion
                  })
                }}"
              />
            </td>
            <td>
              <div class="d-flex">
                <div>
                  {{ dl.value.label }}
                </div>
                <div *ngIf="dl.value.id === 'deploymentAssets' && !environment.production">
                  <button
                  tabindex="0"
                  class="btn btn-lightweight deployment-assets-btn"
                  (click)="openDeploymentAssetsPanel(idx); $event.stopPropagation();"
                  attr.aria-label="{{
                    t('downloadAssetsSubsetSelectAsset.aria', {
                      asset: dl.value.label,
                      order:
                        version?.cadenceName +
                        '-' +
                      t('downloadAssetsVersion.label') +
                        ' ' +
                        version?.cadenceVersion
                    })
                  }}"
                  >
                    <span *ngIf="!isSubset">
                      {{ t('deploymentAssetsPanelOpenButtonAll.title') }}
                    </span>
                    <span *ngIf="isSubset">
                      {{ t('deploymentAssetsPanelOpenButtonSelected.title') }}
                    </span>
                    <img
                        src="/assets/images/deployment-assets-all-icon.svg"
                        class="deployment-assets-icon"
                      />
                  </button>
                </div>
              </div>
            </td>
            <!-- <td>{{ version.cadenceVersion }}</td>
          <td>{{ version.cadenceName }}</td> -->
            <td>
              {{
                version?.dateAvailable ?? ''
                  | translocoDate: { dateStyle: 'short' }
              }}
            </td>
            <td>
              <div
                class="token-wrapper"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                "
              >
                <token
                  *ngIf="dl.value.update.status === 'updateAvailable'"
                  status="primary"
                >
                  <svg
                    localeOverrides="{}"
                    xmlns="http://www.w3.org/2000/svg"
                    class="Information"
                    width="14"
                    height="14"
                    version="1.1"
                    viewBox="0 0 14 14"
                    xml:space="preserve"
                  >
                    <path
                      class="svgToolTipPane"
                      fill="none"
                      d="M0 0H14V14H0z"
                    ></path>
                    <path
                      class="iconColor"
                      d="M7,0C3.134,0,0,3.134,0,7c0,3.866,3.134,7,7,7s7-3.134,7-7C14,3.134,10.866,0,7,0z M7,13 c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S10.309,13,7,13z M7,11c-0.414,0-0.75-0.336-0.75-0.75v-3C6.25,6.836,6.586,6.5,7,6.5 s0.75,0.336,0.75,0.75v3C7.75,10.664,7.414,11,7,11z M6,4c0-0.552,0.448-1,1-1s1,0.448,1,1S7.552,5,7,5S6,4.552,6,4z"
                    ></path>
                  </svg>
                  <span style="margin-inline-start: 0.25rem">{{
                    t('downloadAssetsUpdateAvailable.label')
                  }}</span>
                </token>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
