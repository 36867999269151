import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FooterComponentModule} from '@mysas/portal/shared/ui-layout';
import {DataAccessSitesModule} from '@mysas/portal/sites/data-access-sites';
import {OktaAuthGuard} from '@okta/okta-angular';
import {LayoutComponent} from './layout/layout.component';
import {environment} from "@mysas/shared/util-environment";

@NgModule({
  imports: [
    CommonModule,

    RouterModule.forChild(!environment.production ? [
      {
        path: 'sites',
        component: LayoutComponent,
        canActivateChild: [OktaAuthGuard],
        children: [
          {
            path: '',
            loadChildren: () =>
              import('@mysas/portal/sites/feature-list-sites').then(
                (m) => m.FeatureListModule
              ),
          },
          {
            path: ':tssite',
            loadChildren: () =>
              import('@mysas/portal/sites/feature-site-overview').then(
                (m) => m.FeatureSiteModule
              ),
          },
        ],
      },
    ]:
    []),
    FooterComponentModule,
    DataAccessSitesModule,
  ],
  declarations: [LayoutComponent],
})
export class PortalSitesShellModule {}
