import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  IErrorPayload,
  IResponseEnvelope
} from '@mysas/shared/data-access-common';
import {
  IAddUserToOrder,
  IAsset,
  IChangeUserRoleInOrder, IDeploymentAsset,
  IDownloadLink,
  IInviteUserToOrder,
  IOrder,
  IOrderCadenceOverview,
  IOrderDetail,
  IOrderUser,
  IOrderUsers,
  IPreference
} from '@mysas/shared/data-access-orders';
import { environment } from '@mysas/shared/util-environment';
import { TranslocoService } from '@ngneat/transloco';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, Observable, of, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  protected apiEndpoint: string;

  private translocoService = inject(TranslocoService);
  logger = inject(NGXLogger);

  constructor(private http: HttpClient) {
    this.apiEndpoint = environment.apiConnectorEndpoint;
  }

  public getOrders(): Observable<IOrder[]> {
    return this.http
      .get<IResponseEnvelope<IOrder[]>>(`${this.apiEndpoint}/orders`)
      .pipe(
        map((response: IResponseEnvelope<IOrder[]>) => {
          return response.data;
        }),
        catchError((err) => {
          // TODO come back and add code for error-alerting (unless ngrx is integrated)
          this.logger.error(`Error getting order data`, err);
          return throwError(
            () =>
              new Error(
                this.translocoService.translate('couldNotLoadOrders.txt')
              )
          );
        })
      );
  }

  public getOrderDetails(
    orderNumber: string
  ): Observable<IResponseEnvelope<IOrderDetail>> {
    return this.http
      .get<IResponseEnvelope<IOrderDetail>>(
        `${this.apiEndpoint}/orders/${orderNumber}`
      )
      .pipe(catchError(this.handleApiError.bind(this)));
  }

  public requestOrderAccess(orderNumber: string) {
    return this.http
      .post<IResponseEnvelope<string>>(
        `${this.apiEndpoint}/orders/${orderNumber}/requestOrderAccessEmail`,
        {}
      )
      .pipe(
        map((resp) => resp),
        catchError(this.handleApiError.bind(this))
      );
  }

  public requestSiteAccess(siteNumber: string) {
    return this.http
      .post<IResponseEnvelope<string>>(
        `${this.apiEndpoint}/orders/${siteNumber}/requestSiteAccessEmail`,
        {}
      )
      .pipe(
        map((resp) => resp),

        catchError(this.handleApiError.bind(this))
      );
  }

  /**
   * This method is an attempt at simplifying the process for requesting access to either an
   * order OR site since they use very similar URLs. It also allows passing in form data
   * that has both of these keys, allowing for undefined values.
   *
   * @public
   * @param {(string | undefined)} [orderNumber=undefined]
   * @param {(string | undefined)} [siteNumber=undefined]
   * @returns {*}
   */
  public requestAccess(
    orderNumber: string | undefined = undefined,
    siteNumber: string | undefined = undefined
  ) {
    // return throwError(() => new Error(`testing`)).pipe(delay(400));
    // return of('TESTING').pipe(delay(400));
    if (!orderNumber && !siteNumber) {
      return throwError(() =>
        Error(this.translocoService.translate('orderOrSiteRequired.txt'))
      );
    }
    const route = orderNumber
      ? 'requestOrderAccessEmail'
      : 'requestSiteAccessEmail';
    return this.http
      .post<IResponseEnvelope<string>>(
        `${this.apiEndpoint}/orders/${orderNumber ?? siteNumber}/${route}`,
        {}
      )
      .pipe(
        map((resp) => resp.data),
        catchError(this.handleApiError.bind(this))
      );
  }

  // TODO should this have error handling?
  public reinviteUserToOrder(orderNumber: string, payload: IInviteUserToOrder) {
    return this.http
      .post<IResponseEnvelope<unknown>>(
        `${this.apiEndpoint}/orders/${orderNumber}/invitationEmail`,
        payload
      )
      .pipe(map((resp) => resp.data));
  }

  public updateUserRoleInOrder(
    orderNumber: string,
    email: string,
    payload: IChangeUserRoleInOrder
  ) {
    return this.http
      .put<IResponseEnvelope<IOrderUser>>(
        `${this.apiEndpoint}/orders/${orderNumber}/users/${email}`,
        payload
      )
      .pipe(
        map((resp) => resp.data),
        catchError(this.handleApiError.bind(this))
      );
  }

  public addUserToOrder(orderNumber: string, payload: IAddUserToOrder) {
    console.debug(`Adding user to order`, { orderNumber, ...payload });
    return this.http
      .post<IResponseEnvelope<IOrderUser>>(
        `${this.apiEndpoint}/orders/${orderNumber}/users`,
        payload
      )
      .pipe(
        map((resp) => resp.data),
        catchError((err) => {
          this.logger.debug(`error caught`, err);
          throw err;
        }),
        catchError(this.handleApiError.bind(this))
      );
  }

  public getOrderUsers(orderNumber: string) {
    return this.http
      .get<IResponseEnvelope<IOrderUsers>>(
        `${this.apiEndpoint}/orders/${orderNumber}/users`
      )
      .pipe(
        map((resp) => resp.data),
        catchError(this.handleApiError.bind(this))
      );
  }

  public removeUserFromOrder(orderNumber: string, email: string) {
    return this.http
      .delete<IResponseEnvelope<null>>(
        `${this.apiEndpoint}/orders/${orderNumber}/users/${email}`
      )
      .pipe(
        map((resp) => resp.data),
        catchError(this.handleApiError.bind(this))
      );
  }

  public getAvailableDeploymentAssets(
    orderId: string,
    cadenceName: string,
    cadenceVersion: string
  ) {
    return this.http
    .get<IResponseEnvelope<IDeploymentAsset[]>>(
      `${this.apiEndpoint}/orders/${orderId}/cadenceNames/${cadenceName}/cadenceVersions/${cadenceVersion}/components`
    )
    .pipe(
        map((resp) => resp.data),
        catchError(this.handleApiError.bind(this))
      );
  }


  public getAvailableOrderCadences(orderNumber: string) {
    return this.http
      .get<IResponseEnvelope<IOrderCadenceOverview>>(
        `${this.apiEndpoint}/orders/${orderNumber}/downloads`
      )
      .pipe(
        map((resp) => resp.data),
        catchError(this.handleApiError.bind(this))
      );
  }

  public getOrderDownloadHistory(orderNumber: string) {
    return this.http
      .get<IResponseEnvelope<IAsset[]>>(
        `${this.apiEndpoint}/orders/${orderNumber}/assetHistory`
      )
      .pipe(
        map((resp) => resp.data),
          catchError(this.handleApiError.bind(this))
      );
  }

  public downloadAsset(asset: IAsset): Observable<Blob> {
    // const url = asset.link.href.replace('reofs', 'reofs-okta');
    const url = asset.link.href.replace(/\/(reofs[\w-]*)\//, '/$1-okta/');
    return this.http.get(url, { responseType: 'blob' });
  }

  public downloadDocument(doc: IDownloadLink): Observable<HttpResponse<Blob>> {
    // const url = doc.href.replace('reofs', 'reofs-okta');
    const url = doc.href.replace(/\/(reofs[\w-]*)\//, '/$1-okta/');
    return this.http
      .get(url, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        tap((resp) => {
          const keys = resp.headers.keys();
          /* istanbul ignore next */
          console.log(keys.map((key) => `${key}: ${resp.headers.get(key)}`));
        })
      );
  }

  public downloadAssetBundle(
    link: string,
    assets: Record<string, boolean>
  ): Observable<HttpResponse<Blob>> {
    const params = new HttpParams({ fromObject: assets });
    // const url = link.replace('reofs', 'reofs-okta');
    const url = link.replace(/\/(reofs[\w-]*)\//, '/$1-okta/');

    // this.logger.debug(`Downloading asset bundle with params`, params);
    return this.http
      .get(
        url,
        // responseType tells the browser that the body is of type Blob
        // observe means that we're not passing just data, the whole response obj with headers
        { params, responseType: 'blob', observe: 'response' }
      )
      .pipe(map((res) => res));
  }

  public getUserPrefs(): Observable<IPreference[]> {
    return this.http
      .get<IResponseEnvelope<{ preferences: IPreference[] }>>(
        `${this.apiEndpoint}/orders/users/preferences`
      )
      .pipe(
        map((resp) => resp.data.preferences),
        // tap((data) => this.logger.debug(data)),
        catchError(this.handleApiError.bind(this))
      );
  }

  public updateUserPreference(preference: IPreference) {
    // API doesn't want the pKey sent in the payload, and it also doesn't
    // return a value for it on a successful call - so pull it out here
    // and inject it in the return value
    const { pKey, ...rest } = preference;
    return this.http
      .put<IResponseEnvelope<{ preferences: IPreference[] }>>(
        `${this.apiEndpoint}/orders/users/preferences`,
        rest
      )
      .pipe(
        map((resp) => ({ ...resp.data.preferences[0], pKey })),
        catchError(this.handleApiError.bind(this))
      );
  }

  private handleApiError(error: unknown): Observable<never> {
    // this.logger.debug(`Evaluating incoming error`, error);
    if (error instanceof HttpErrorResponse) {
      if (error.error?.data) {
        // this is a proper instance of IErrorPayload, pull out end user message
        const payload = error.error as IResponseEnvelope<IErrorPayload>;
        // this.logger.debug(`Extracting error response`, payload);
        return throwError(() => new Error(payload.data.endUserMessage));
      }
      // not an instance of IErrorResponse
      if (error.status >= 500 || error.status === 0) {
        // TODO - calling translate here always returns `this is undefined`
        // const errMessage = this.translocoService.translate('loadingError.txt');
        return throwError(() => {
          const msg = this.translocoService.translate(
            'unableToLoadInformation.txt'
          );
          return new Error(msg);
        });
      }
      return throwError(() => new Error(error.message));
    } else if (error instanceof Error) {
      return throwError(() => new Error(error.message));
    }
    return throwError(() => error);
  }
}
