import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationsFacade} from '@mysas/portal/shared/data-access-notifications';
import {UserFacade} from '@mysas/portal/shared/data-access-user';
import {routerSelectors} from '@mysas/portal/shared/util-state';
import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {routerNavigatedAction} from '@ngrx/router-store';
import {Store} from '@ngrx/store';
import {catchError, distinctUntilKeyChanged, filter, ignoreElements, map, of, switchMap} from 'rxjs';
import {SitesService} from '../services/sites.service';
import * as SitesActions from './sites.actions';

@Injectable()
export class SitesEffects {
  // init$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(SitesActions.initSites),
  //     skipUntil(
  //       this.userFacade.loggedIn$.pipe(filter((loggedIn) => loggedIn === true))
  //     ),
  //     switchMap(() =>
  //       this.sitesService.getSitesForCurrentUser().pipe(
  //         map((sites) => SitesActions.loadSitesSuccess({ sites })),
  //         catchError((err) => {
  //           return of(SitesActions.loadSitesFailure({ error: err.message }));
  //         })
  //       )
  //     )
  //   );
  // });

  searchSites$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SitesActions.searchSites),
      switchMap(({query}) =>
        this.sitesService.getSites(query).pipe(
          map((sites) => SitesActions.loadSitesSuccess({ sites })),
          catchError((err) => {
            return of(SitesActions.loadSitesFailure({ error: err.message }));
          })
        )
      )
    );
  });

  /**
   * This effect updates the URL whenever the filter for the list of sites has changed. It
   * uses the `distinctUntilKeyChanged` operator to prevent an endless cycle of updates where
   * `updateFilterFromUrl$` sees the URL change and acts accordingly.
   */
  updateUrlSiteFilter$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SitesActions.changeFilter),
        distinctUntilKeyChanged('filter'),
        map(({ filter }) => {
          const params = filter ? { filter } : null;
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
          });
        }),
        ignoreElements()
      );
    },
    { dispatch: false }
  );

  /**
   * This effect watches URL changes for a route that starts with '/sites', and then attempts
   * to extract a value for the query param 'siteNumber'. If this param doesn't exist or it's value is null,
   * then the filter is set to null as well.
   */
  updateFilterFromUrl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(routerNavigatedAction),
      filter(({ payload }) => payload.routerState.url.startsWith('/sites')),
      concatLatestFrom(() =>
        this.store.select(routerSelectors.selectQueryParamNgrx('filter'))
      ),
      map(([, filter]) => {
        return SitesActions.changeFilter({ filter: filter ?? null });
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private sitesService: SitesService,
    private notificationFacade: NotificationsFacade,
    private userFacade: UserFacade,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store
  ) {}

  // ngrxOnInitEffects(): Action {
  //   return SitesActions.initSites();
  // }
}
