import {IOrder, SortByType, SortDirectionType,} from '@mysas/shared/data-access-orders';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, ActionCreator, createReducer, Creator, on, ReducerTypes,} from '@ngrx/store';

import * as OrdersActions from './orders.actions';

export const ORDERS_FEATURE_KEY = 'orders';

export interface OrdersState extends EntityState<IOrder> {
  selectedId?: string | number; // which Orders record has been selected
  loaded: boolean; // has the Orders list been loaded
  error?: string | null; // last known error (if any)
  sortBy: SortByType;
  sortDirection: SortDirectionType;
  filter?: string | null;
  accessRequest: {
    processing: boolean;
    open: boolean;
    error: string | null;
  };
}

export interface OrdersPartialState {
  readonly [ORDERS_FEATURE_KEY]: OrdersState;
}

export const ordersAdapter: EntityAdapter<IOrder> = createEntityAdapter<IOrder>(
  {
    selectId: (order: IOrder) => order.number,
  }
);

export const initialOrdersState: OrdersState = ordersAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  sortBy: 'site',
  sortDirection: 'descending',
  filter: null,
  accessRequest: {
    processing: false,
    open: false,
    error: null,
  },
});

const onAccessRequestActions: ReducerTypes<
  OrdersState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly ActionCreator<string, Creator<any[], object>>[]
>[] = [
  on(OrdersActions.openAccessRequestDialog, (state) => ({
    ...state,
    accessRequest: {
      open: true,
      processing: false,
      error: null,
    },
  })),
  on(OrdersActions.closeAccessRequestDialog, (state) => ({
    ...state,
    accessRequest: {
      open: false,
      processing: false,
      error: null,
    },
  })),
  on(OrdersActions.submitAccessRequest, (state) => ({
    ...state,
    accessRequest: {
      error: null,
      processing: true,
      open: true,
    },
  })),
  on(
    OrdersActions.submitAccessRequestSuccess,
    OrdersActions.submitAccessRequestFailure,
    (state) => ({
      ...state,
      accessRequest: {
        ...state.accessRequest,
        open: false,
        processing: false,
      },
    })
  ),
  on(OrdersActions.submitAccessRequestFailure, (state, { error }) => ({
    ...state,
    accessRequest: {
      ...state.accessRequest,
      error,
    },
  })),
];

const sortAndFilterActions: ReducerTypes<
  OrdersState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly ActionCreator<string, Creator<any[], object>>[]
>[] = [
  on(
    OrdersActions.toggleSortDirection,
    (state): OrdersState => ({
      ...state,
      sortDirection:
        state.sortDirection === 'ascending' ? 'descending' : 'ascending',
    })
  ),
  on(
    OrdersActions.changeSortBy,
    (state, { sortBy }): OrdersState => ({
      ...state,
      sortBy,
    })
  ),
  on(
    OrdersActions.changeSortDirection,
    (state, { sortDirection }): OrdersState => ({
      ...state,
      sortDirection,
    })
  ),
  on(
    OrdersActions.changeFilter,
    (state, { filter }): OrdersState => ({
      ...state,
      filter,
    })
  ),
];

const reducer = createReducer(
  initialOrdersState,
  on(
    OrdersActions.initOrders,
    (state): OrdersState => ({
      ...state,
      loaded: false,
      error: null,
    })
  ),
  on(
    OrdersActions.loadOrdersSuccess,
    (state, { orders }): OrdersState =>
      ordersAdapter.setAll(orders, { ...state, loaded: true })
  ),
  on(
    OrdersActions.loadOrdersFailure,
    (state, { error }): OrdersState => ({
      ...state,
      loaded: true,
      error,
    })
  ),

  ...onAccessRequestActions,
  ...sortAndFilterActions
);

export function ordersReducer(state: OrdersState | undefined, action: Action) {
  return reducer(state, action);
}
