/* eslint-disable @typescript-eslint/no-explicit-any */
import {ILoadingState} from '@mysas/portal/shared/util-state';
import {Action, ActionCreator, createReducer, Creator, on, ReducerTypes} from '@ngrx/store';

import * as SiteOverviewActions from './site-overview.actions';
import {ILicense, ISiteDetail} from "@mysas/shared/data-access-sites";

export const SITE_OVERVIEW_FEATURE_KEY = 'siteOverview';

export interface SiteOverviewState extends Partial<ISiteDetail> {
  loading: {
    details: ILoadingState;
    license: ILoadingState;
  };
  license: ILicense | null
}

export interface SiteOverviewPartialState {
  readonly [SITE_OVERVIEW_FEATURE_KEY]: SiteOverviewState;
}

export const initialSiteOverviewState: SiteOverviewState = {
  loading: {
    details: {
      status: 'NOT_LOADED',
      success: false,
    },
    license: {
      status: 'NOT_LOADED',
      success: false,
    },
  },
  license: null
};

const siteDetailOns: ReducerTypes<
  SiteOverviewState,
  readonly ActionCreator<string, Creator<any[], object>>[]
>[] = [
  on(
    SiteOverviewActions.LoadSiteDetail.load,
    (state): SiteOverviewState => ({
      ...state,
      loading: {
        ...state.loading,
        details: {
          status: 'LOADING',
          success: false,
        },
      },
    })
  ),
  on(
    SiteOverviewActions.LoadSiteDetail.success,
    (state, { detail }): SiteOverviewState => ({
      ...state,
      ...detail,
      loading: {
        ...state.loading,
        details: {
          status: 'LOADED',
          success: true,
        },
      },
    })
  ),
  on(SiteOverviewActions.LoadSiteDetail.failure, (state, { error }) => ({
    ...state,
    loading: {
      ...state.loading,
      details: {
        status: 'LOADED',
        success: false,
        error,
      },
    },
  })),
];

const licenseExtensionOns: ReducerTypes<
  SiteOverviewState,
  readonly ActionCreator<string, Creator<any[], object>>[]
  >[] = [
  on(
    SiteOverviewActions.LicenseExtension.request,
    (state): SiteOverviewState => ({
      ...state,
      loading: {
        ...state.loading,
        license: {
          status: 'LOADING',
          success: false,
        },
      },
    })
  ),
  on(
    SiteOverviewActions.LicenseExtension.success,
    (state, { license }): SiteOverviewState => ({
      ...state,
      ...license,
      loading: {
        ...state.loading,
        license: {
          status: 'LOADED',
          success: true,
        },
      },
    })
  ),
  on(SiteOverviewActions.LicenseExtension.failure, (state, { error }) => ({
    ...state,
    loading: {
      ...state.loading,
      license: {
        status: 'LOADED',
        success: false,
        error,
      },
    },
  })),
];

const reducer = createReducer(
  initialSiteOverviewState,
  ...siteDetailOns,
  ...licenseExtensionOns,
  on(
    SiteOverviewActions.initSiteOverview,
    (): SiteOverviewState => ({
      ...initialSiteOverviewState,
    })
  ),
);

export function siteOverviewReducer(
  state: SiteOverviewState | undefined,
  action: Action
) {
  return reducer(state, action);
}
