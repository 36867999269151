import {inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {combineLatest, map} from 'rxjs';

import {SortByType, SortDirectionType, sortSites} from '@mysas/shared/data-access-sites';
import {NGXLogger} from 'ngx-logger';
import * as SitesActions from './sites.actions';
import * as SitesSelectors from './sites.selectors';

@Injectable({
  providedIn: 'root',
})
export class SitesFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.select(SitesSelectors.getSitesLoaded);
  allSites$ = this.store.select(SitesSelectors.getAllSites);
  selectedSites$ = this.store.select(SitesSelectors.getSelected);
  sortBy$ = this.store.select(
    SitesSelectors.getSitesSortBy
    // distinctUntilChanged()
  );
  sortDirection$ = this.store.select(
    SitesSelectors.getSitesSortDirection
    // distinctUntilChanged()
  );
  filter$ = this.store.select(SitesSelectors.getSitesFilter);

  sortedAndFilteredSites$ = combineLatest([
    this.allSites$,
    this.sortBy$,
    this.sortDirection$,
    this.filter$,
  ]).pipe(
    // tap(([, sortBy, sortDirection, filter]) =>
    //   this.logger.debug(JSON.stringify({ sortBy, sortDirection, filter }, null, 2))
    // ),
    map(([sites, sortBy, sortDirection, filter]) =>
      sites
        .sort((a, b) => sortSites(a, b, sortBy, sortDirection))
        .filter((site) =>
          filter
            ? site.country.toLowerCase().includes(filter.toLowerCase()) ||
              site.company.toLowerCase().includes(filter.toLowerCase()) ||
              site.tssite.toLowerCase().includes(filter.toLowerCase())
            : true
        )
    )
  );

  logger = inject(NGXLogger);

  constructor(private readonly store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  // init() {
  //   this.logger.debug(`[SitesFacade] Dispatching action to load sites`);
  //   this.store.dispatch(SitesActions.initSites());
  // }

  search(query: string) {
    this.logger.debug(`[SitesFacade] Dispatching action to load sites`);
    this.store.dispatch(SitesActions.searchSites({ query }));
  }

  changeSortBy(sortBy: SortByType) {
    this.store.dispatch(SitesActions.changeSortBy({ sortBy }));
  }

  changeSortDirection(sortDirection: SortDirectionType) {
    this.store.dispatch(SitesActions.changeSortDirection({ sortDirection }));
  }

  toggleSortDirection() {
    this.store.dispatch(SitesActions.toggleSortDirection());
  }

  changeFilter(filter: string | null) {
    this.store.dispatch(SitesActions.changeFilter({ filter }));
  }
}
