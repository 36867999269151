import {Overlay} from '@angular/cdk/overlay';
import {ComponentPortal, ComponentType} from '@angular/cdk/portal';
import {Injectable, InjectionToken, Injector} from '@angular/core';
import {PanelRef} from './panel-ref';
import {IAssetPanelData, IDeploymentAssetPanelData} from "@mysas/portal/orders/ui-order-overview";
import {ITempLicensePanelData} from "@mysas/portal/sites/ui-site-overview";

export interface IAssetPanelConfig {
  data?: IAssetPanelData;
}

export interface IDeploymentAssetPanelConfig {
  data?: IDeploymentAssetPanelData;
}

export interface ITempLicensePanelConfig {
  data?: ITempLicensePanelData;
}

export const ASSET_PANEL_DATA = new InjectionToken<IAssetPanelData>('ASSET_PANEL_DATA');
export const DEPLOYMENT_ASSET_PANEL_DATA = new InjectionToken<IDeploymentAssetPanelData>('DEPLOYMENT_ASSET_PANEL_DATA');
export const TEMP_LICENSE_PANEL_DATA = new InjectionToken<IDeploymentAssetPanelData>('TEMP_LICENSE_PANEL_DATA');

@Injectable({
  providedIn: 'root',
})
export class PanelService {
  constructor(private overlay: Overlay, private injector: Injector) {}

  private setupPanel<T>(
    component: ComponentType<T>,
    config: IAssetPanelConfig | IDeploymentAssetPanelConfig | ITempLicensePanelConfig | undefined,
    data: InjectionToken<IAssetPanelData | IDeploymentAssetPanelData | ITempLicensePanelConfig>) {
    // Globally centered position strategy
    const positionStrategy = this.overlay.position().global().right();
    // Create the overlay with customizable options
    const overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'sasds-dialog__backdrop',
      panelClass: 'overlay-panel',
    });

    // Create dialogRef to return
    const panelRef = new PanelRef<T>(overlayRef);

    // Create injector to be able to reference the DialogRef from within the component
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: PanelRef, useValue: panelRef },
        { provide: data, useValue: config?.data },
      ],
    });

    // Attach component portal to the overlay
    const portal = new ComponentPortal(component, null, injector);
    const componentRef = overlayRef.attach(portal);
    (panelRef as { componentInstance: T }).componentInstance =
      componentRef.instance;

    return panelRef;
  }

  openAssetPanel<T>(
    component: ComponentType<T>,
    config?: IAssetPanelConfig
  ): PanelRef<T> {
    return this.setupPanel(component, config, ASSET_PANEL_DATA);
  }

  openDeploymentAssetPanel<T>(
    component: ComponentType<T>,
    config?: IDeploymentAssetPanelConfig
  ): PanelRef<T> {
    return this.setupPanel(component, config, DEPLOYMENT_ASSET_PANEL_DATA);
  }

  openTempLicensePanel<T>(
    component: ComponentType<T>,
    config?: ITempLicensePanelConfig
  ): PanelRef<T> {
    return this.setupPanel(component, config, TEMP_LICENSE_PANEL_DATA);
  }
}
