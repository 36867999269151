import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {AssetType, IAssetDownload, ICadence, IDownloadLink} from '@mysas/shared/data-access-orders';
import {
  AvailableDeploymentAssetsState,
  SelectedDeploymentAssetsState
} from 'libs/portal/orders/data-access-orders/src/lib/order-overview-state/order-overview.reducer';
import {NGXLogger} from 'ngx-logger';
import {environment} from '@mysas/shared/util-environment';

interface IChecklistItem {
  id: number;
  value: IAssetDownload;
  isSelected: boolean;
}

@Component({
  selector: 'mysas-version-asset-table',
  templateUrl: './version-asset-table.component.html',
  styleUrls: ['./version-asset-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionAssetTableComponent implements OnChanges {
  @Input() version: ICadence | undefined;
  @Input() availableDeploymentAssets: AvailableDeploymentAssetsState | undefined | null;
  @Input() selectedDeploymentAssets: SelectedDeploymentAssetsState | undefined | null;

  environment = environment;

  private _downloadInProgress = false;
  @Input() set downloadInProgress(
    data: { uriName: string; uriVersion: string } | null
  ) {
    if (this.version && data !== null) {
      this._downloadInProgress =
        this.version.uriName === data.uriName &&
        this.version.uriVersion === data.uriVersion;
    } else {
      this._downloadInProgress = false;
    }
  }
  get disableDownloadBtn() {
    return this._downloadInProgress;
  }

  @Output() activatePanel = new EventEmitter<AssetType[]>();
  @Output() activateDeploymentAssetsPanel = new EventEmitter<boolean>();

  @Output() downloadAssets = new EventEmitter<{
    assetTypes: {
      certificates: boolean;
      license: boolean;
      deploymentAssets: boolean;
    };
    version: ICadence;
  }>();

  @Output() downloadReadme = new EventEmitter<IDownloadLink>();

  masterSelected = false;
  isSubset = false;
  checkList: Array<IChecklistItem> = [];
  checkedList: Array<IChecklistItem> = [];

  logger = inject(NGXLogger);

  ngOnChanges(changes: SimpleChanges): void {
    // this.logger.debug(`changes`, changes);
    if (changes['version']?.currentValue) {
      this.checkList = this.generateCheckList(changes['version'].currentValue);
    }
    if(!!this.version) {
      if((changes['availableDeploymentAssets']?.currentValue && changes['availableDeploymentAssets']?.currentValue != this.availableDeploymentAssets) || (changes['selectedDeploymentAssets']?.currentValue && changes['availableDeploymentAssets']?.currentValue != this.selectedDeploymentAssets)) {
        let selectedLength = this.selectedDeploymentAssets?.deploymentAssets[this.version.uriName + this.version.uriVersion] ? this.selectedDeploymentAssets.deploymentAssets[this.version.uriName + this.version.uriVersion].length: 0;
        if(selectedLength > 0) {
          this.isSubset = this.availableDeploymentAssets ? this.availableDeploymentAssets.deploymentAssets[this.version.uriName + this.version.uriVersion]?.length > selectedLength: false
        }
      }
      // if(!!this.selectedDeploymentAssets && changes['availableDeploymentAssets']?.currentValue) {
      //   this.isSubset = changes['availableDeploymentAssets']?.currentValue.length > this.selectedDeploymentAssets.deploymentAssets[this.version.cadenceName + this.version.cadenceVersion].length &&
      //   this.selectedDeploymentAssets.deploymentAssets[this.version.cadenceName + this.version.cadenceVersion].length != 0;
      // }
      // if(!!this.availableDeploymentAssets && changes['selectedDeploymentAssets']?.currentValue) {
      //   this.isSubset = this.availableDeploymentAssets.length > changes['selectedDeploymentAssets']?.currentValue.deploymentAssets[this.version.cadenceName + this.version.cadenceVersion].length &&
      //   changes['selectedDeploymentAssets']?.currentValue.deploymentAssets[this.version.cadenceName + this.version.cadenceVersion].length != 0;
      // }
    }
  }

  get deployDoc() {
    return this.version?.docLinks.find((d) => d.rel === 'deploymentDocument')
      ?.href;
  }

  get readmeDoc() {
    return this.version?.docLinks.find((d) => d.rel === 'deploymentReadme')
      ?.href;
  }

  get releaseNoteDoc() {
    return this.version?.docLinks.find((d) => d.rel === 'releaseNotes')?.href;
  }

  get anyChecked() {
    return this.checkedList.some((v) => v['isSelected']);
  }

  toggleCheckAll() {
    for (let i = 0; i < this.checkList.length; i++) {
      this.checkList[i]['isSelected'] = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  toggleCheck(idx: number) {
    this.checkList[idx]['isSelected'] = !this.checkList[idx]['isSelected'];
    this.getCheckedItemList();
    this.isAllSelected();
  }

  openPanel() {
    const assetTypes: AssetType[] = this.anyChecked
      ? this.checkedList.map((item) => item.value.id)
      : this.checkList.map((i) => i.value.id);
    this.activatePanel.emit(assetTypes);
  }

  openDeploymentAssetsPanel(idx: number) {
    this.checkList[idx]['isSelected'] = true;
    this.getCheckedItemList();
    this.isAllSelected();
    this.activateDeploymentAssetsPanel.emit();
  }

  triggerDownloads() {
    const assetTypes = this.checkedList.map((item) => item.value.id);
    this.downloadAssets.emit({
      assetTypes: {
        license: assetTypes.includes('license'),
        deploymentAssets: assetTypes.includes('deploymentAssets'),
        certificates: assetTypes.includes('certificates'),
      },
      version: this.version as ICadence,
    });
  }

  isAllSelected() {
    this.masterSelected = this.checkList.every(
      (item) => item['isSelected'] === true
    );
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (let i = 0; i < this.checkList.length; i++) {
      if (this.checkList[i]['isSelected']) {
        this.checkedList.push(this.checkList[i]);
      }
    }
  }

  triggerReadmeDownload() {
    this.downloadReadme.emit(
      /* istanbul ignore next */
      this.version?.docLinks.find((d) => d.rel === 'deploymentReadme')
    );
  }

  private generateCheckList(version: ICadence) {
    const rv = [
      ...version.assets.downloads.map((asset: IAssetDownload, idx: number) => ({
        id: idx,
        value: asset,
        isSelected: false,
      })),
    ];
    return rv;
  }
}
