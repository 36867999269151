<ng-container *transloco="let t">
  <div class="form-group-row">
    <div class="col">
      <table *ngIf="availableDeploymentAssets" class="table">
        <thead>
          <tr>
            <th style="vertical-align: middle; text-align: center">
              <input
                tabindex="0"
                type="checkbox"
                [(ngModel)]="masterSelected"
                (change)="toggleCheckAll()"
                attr.aria-label="{{ t('deploymentAssetsPanelSelectAll.aria') }}"
              />
            </th>
            <th>
              <div class="subset-table-header-row">
                <div>
                  {{ t('deploymentAssetsPanelTableHeader.label') }}
                </div>
                <div>
                  {{ t('deploymentAssetsPanelTableCount.label') }} ({{checkedList.length}})
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let dl of checkList; let idx = index"
            (click)="toggleCheck(idx)"
          >
            <td style="vertical-align: middle; text-align: center">
              <input
                tabindex="0"
                class="asset-checkbox"
                type="checkbox"
                name="{{ dl.value.name }}_checkbox"
                value="{{ dl.id }}"
                [(ngModel)]="dl.isSelected"
                (change)="isAllSelected()"
                attr.aria-label="{{
                  t('deploymentAssetsPanelSelect.aria', {
                      asset: data?.version?.cadenceName,
                      cadenceVersion: data?.version?.cadenceVersion
                  })
                }}"
              />
            </td>
            <td>
              {{ dl.value.legalName }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="form-group-row">
    <div class="col">
      <button
        tabindex="0"
        class="btn btn-primary"
        attr.aria-label="{{ t('deploymentAssetsPanelSave.aria') }}"
        type="submit"
        [disabled]="checkedList.length === 0"
        (click)="saveSubsetList()"
      >
        {{ t('deploymentAssetsPanelSave.label') }}
      </button>
    </div>
    <div class="col">
      <button
        tabindex="0"
        class="btn btn-standard-no-bd"
        attr.aria-label="{{ t('deploymentAssetsPanelCancel.aria') }}"
        (click)="close()"
      >
        {{ t('deploymentAssetsPanelCancel.label') }}
      </button>
    </div>
  </div>
</ng-container>
