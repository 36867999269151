import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, inject, Inject, ViewChild} from '@angular/core';
import {
  AssetDownloadService,
  ICadenceVersionWithHistory,
  OrderOverviewFacade
} from '@mysas/portal/orders/data-access-orders';
import {ToasterService} from '@mysas/portal/shared/data-access-toaster';
import {AssetType, IAsset, IOrderUser} from '@mysas/shared/data-access-orders';
import {TranslocoService} from '@ngneat/transloco';
import {BehaviorSubject, finalize, map} from 'rxjs';
import {ASSET_PANEL_DATA, PanelRef} from "@mysas/portal/shared/util-panel-service";

/**
 * Payload interface that contains data to be displayed in the panel
 */
export interface IAssetPanelData {
  users: IOrderUser[];
  version: ICadenceVersionWithHistory;

  /**
   * Users are able to specify one or more assets before opening the panel. This
   * propterty instructs the on panel on which assets to show
   */
  assetTypes: Array<AssetType>;
}
@Component({
  selector: 'mysas-asset-panel',
  templateUrl: './asset-panel.component.html',
  styleUrls: ['./asset-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetPanelComponent implements AfterViewInit {
  @ViewChild('dismissAssetPanelButton')
  firstFocus!: ElementRef;

  /**
   * TODO - not a fan of injecting something from a data-access library here, it breaks
   * the "dumb component" convention. Need to figure out the best way to handle this.
   *
   * This was put in place because we can't (can, shouldn't?) send an observable as a value
   * in the injected PANEL_DATA object. That chain of inheritence would look like:
   * - OrderOverviewFacade.assetPanelState$
   * - OrderOverviewComponent passes assetPanelState$ to the `.open()` method of the PanelService
   * - This component would then reference data.assetPanelState$ in the template
   */
  private overviewFacade = inject(OrderOverviewFacade);

  private assetDownloader = inject(AssetDownloadService);

  private panelRef = inject(PanelRef);
  downloadInProgress$ = new BehaviorSubject<string | null>(null);

  loadingState$ = this.overviewFacade.loadingStateHistory$;
  error$ = this.overviewFacade.loadingStateHistory$.pipe(map((s) => s.error));

  constructor(
    @Inject(ASSET_PANEL_DATA) public data: IAssetPanelData,
    private toaster: ToasterService,
    private transloco: TranslocoService
  ) {}

  ngAfterViewInit(): void {
    this.firstFocus?.nativeElement.focus();
  }
  close() {
    console.log('close dialog');
    this.panelRef.close();
  }
  get hasPatchUpdate() {
    return (
      this.data.version.patchUpdate.status === 'updateAvailable' &&
      this.data.assetTypes.includes('deploymentAssets')
    );
  }

  get hasLicenseUpdate() {
    return (
      this.data.version.licenseUpdate.status === 'updateAvailable' &&
      this.data.assetTypes.includes('license')
    );
  }

  triggerUpdateDownload(assetType: 'license' | 'deploymentAssets') {
    const assets = {
      license: assetType === 'license',
      deploymentAssets: assetType === 'deploymentAssets',
      certificates: false,
    };
    this.overviewFacade.downloadAssets(this.data.version, assets);
  }

  downloadAsset(asset: IAsset) {
    this.downloadInProgress$.next(asset.name);
    this.assetDownloader
      .downloadAssetWithProgress(asset)
      .pipe(finalize(() => this.downloadInProgress$.next(null)))
      .subscribe({
        error: (error: Error) => {
          this.toaster.open({
            text: this.transloco.translate(
              'assetDownloadToastFailure.txt',
              { message: error.message },
              'orderOverview'
            ),
            position: { bottom: 128, right: 32 },
          });
        },
      });
  }
}
