import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as SiteOverviewActions from './site-overview.actions';
import {LicenseExtension} from './site-overview.actions';

import * as SiteOverviewSelectors from './site-overview.selectors';
import {take} from "rxjs";

@Injectable()
export class SiteOverviewFacade {
  site$ = this.store.select(SiteOverviewSelectors.getSiteDetails);

  loadingStateDetails$ = this.store.select(
    SiteOverviewSelectors.selectLoadingStateSiteDetails
  );

  loadingStateLicenseExtension$ = this.store.select(
    SiteOverviewSelectors.selectLoadingStateLicenseExtension
  );

  constructor(
    private readonly store: Store
  ) {}

  initSiteOverview() {
    this.store.dispatch(SiteOverviewActions.initSiteOverview());
  }

  requestLicenseExtension(selectedReleaseKey: string) {
    this.site$.pipe(take(1)).subscribe((site) => {
      this.store.dispatch(
        LicenseExtension.request({
          tssite: site?.tssite as string,
          releaseGroup: selectedReleaseKey
        })
      );
    });
  }
}
