import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, inject, Inject, ViewChild} from '@angular/core';
import {
  AssetDownloadService,
  ICadenceVersionWithHistory,
  OrderOverviewFacade
} from '@mysas/portal/orders/data-access-orders';
import {ToasterService} from '@mysas/portal/shared/data-access-toaster';
import {IAsset, IDeploymentAsset} from '@mysas/shared/data-access-orders';
import {TranslocoService} from '@ngneat/transloco';
import {BehaviorSubject, finalize, map} from 'rxjs';
import {DEPLOYMENT_ASSET_PANEL_DATA, PanelRef} from '@mysas/portal/shared/util-panel-service';

/**
 * Payload interface that contains data to be displayed in the panel
 */
export interface IDeploymentAssetPanelData {
  orderNum: string;
  version: ICadenceVersionWithHistory;
}

@Component({
  selector: 'mysas-deployment-asset-panel',
  templateUrl: './deployment-asset-panel.component.html',
  styleUrls: ['./deployment-asset-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeploymentAssetPanelComponent implements AfterViewInit {
  @ViewChild('dismissAssetPanelButton')
  firstFocus!: ElementRef;

  /**
   * TODO - not a fan of injecting something from a data-access library here, it breaks
   * the "dumb component" convention. Need to figure out the best way to handle this.
   *
   * This was put in place because we can't (can, shouldn't?) send an observable as a value
   * in the injected PANEL_DATA object. That chain of inheritence would look like:
   * - OrderOverviewFacade.assetPanelState$
   * - OrderOverviewComponent passes assetPanelState$ to the `.open()` method of the PanelService
   * - This component would then reference data.assetPanelState$ in the template
   */
  private orderOverviewFacade = inject(OrderOverviewFacade);

  private assetDownloader = inject(AssetDownloadService);

  private panelRef = inject(PanelRef);

  downloadInProgress$ = new BehaviorSubject<string | null>(null);

  loadingStateDeploymentAssets$ = this.orderOverviewFacade.loadingStateDeploymentAssets$;
  error$ = this.orderOverviewFacade.loadingStateDeploymentAssets$.pipe(map((s) => s.error));
  availableDeploymentAssets$ = this.orderOverviewFacade.availableDeploymentAssets$.pipe(map((x) => x.deploymentAssets[this.data.version.uriName + this.data.version.uriVersion]));
  selectedDeploymentAssets$ = this.orderOverviewFacade.selectedDeploymentAssets$;

  constructor(
    @Inject(DEPLOYMENT_ASSET_PANEL_DATA) public data: IDeploymentAssetPanelData,
    private toaster: ToasterService,
    private transloco: TranslocoService
  ) {}

  ngAfterViewInit(): void {
    this.firstFocus?.nativeElement.focus();
  }

  saveSubsetList(selectedDeploymentAssets: IDeploymentAsset[]) {
    this.orderOverviewFacade.setDeploymentSubset(selectedDeploymentAssets, this.data.version);
    this.panelRef.close();
  }

  close() {
    console.log('close dialog');
    this.panelRef.close();
  }

  get hasPatchUpdate() {
    return (
      this.data.version.patchUpdate.status === 'updateAvailable'
    );
  }

  triggerUpdateDownload() {
    const assets = {
      license: false,
      deploymentAssets: true,
      certificates: false
    };
    this.orderOverviewFacade.downloadAssets(this.data.version, assets);
  }

  downloadAsset(asset: IAsset) {
    this.downloadInProgress$.next(asset.name);
    this.assetDownloader
      .downloadAssetWithProgress(asset)
      .pipe(finalize(() => this.downloadInProgress$.next(null)))
      .subscribe({
        error: (error: Error) => {
          this.toaster.open({
            text: this.transloco.translate(
              'assetDownloadToastFailure.txt',
              { message: error.message },
              'orderOverview'
            ),
            position: { bottom: 128, right: 32 },
          });
        },
      });
  }
}
