import {
  ChangeDetectionStrategy,
  Component, EventEmitter, Input, OnChanges, Output
} from '@angular/core';
import {
  ICadenceVersionWithHistory
} from '@mysas/portal/orders/data-access-orders';
import {
  IDeploymentAsset
} from '@mysas/shared/data-access-orders';
import { SelectedDeploymentAssetsState } from 'libs/portal/orders/data-access-orders/src/lib/order-overview-state/order-overview.reducer';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

/**
 * Payload interface that contains data to be displayed in the panel
 */
export interface IDeploymentAssetPanelData {
  orderNum: string;
  version: ICadenceVersionWithHistory;
}

interface IChecklistItem {
  id: number;
  value: IDeploymentAsset;
  isSelected: boolean;
}

@Component({
  selector: 'mysas-deployment-asset-table',
  templateUrl: './deployment-asset-table.component.html',
  styleUrls: ['./deployment-asset-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeploymentAssetTableComponent implements OnChanges {
  @Input() availableDeploymentAssets: IDeploymentAsset[] | undefined | null;
  @Input() selectedDeploymentAssets: SelectedDeploymentAssetsState | undefined | null;
  @Input() data: IDeploymentAssetPanelData | undefined | null;

  masterSelected = true;
  checkList: IChecklistItem[]  = [];
  checkedList: IChecklistItem[]  = [];

  downloadInProgress$ = new BehaviorSubject<string | null>(null);

  @Output() saveSubset = new EventEmitter<IDeploymentAsset[]>();
  @Output() closePanel = new EventEmitter<void>();


  constructor(
  ) {}

  ngOnChanges(): void {
    // this.logger.debug(`changes`, changes);
    if (!!this.availableDeploymentAssets && this.selectedDeploymentAssets && this.data?.version) {
      this.checkList = this.generateCheckList(this.availableDeploymentAssets, this.selectedDeploymentAssets.deploymentAssets[this.data.version.uriName + this.data.version.uriVersion]);
      this.isAllSelected();
    }
  }

  saveSubsetList() {
    this.saveSubset.emit(this.checkedList.map(cl => cl.value));
  }

  close() {
    this.closePanel.emit();
  }

  toggleCheckAll() {
    for (let i = 0; i < this.checkList.length; i++) {
      this.checkList[i]['isSelected'] = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  toggleCheck(idx: number) {
    this.checkList[idx]['isSelected'] = !this.checkList[idx]['isSelected'];
    this.getCheckedItemList();
    this.isAllSelected();
  }

  isAllSelected() {
    this.masterSelected = this.checkList.every(
      (item) => item['isSelected'] === true
    );
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (let i = 0; i < this.checkList.length; i++) {
      if (this.checkList[i]['isSelected']) {
        this.checkedList.push(this.checkList[i]);
      }
    }
  }

  private generateCheckList(availableDeploymentAssets: IDeploymentAsset[], selectedDeploymentAssets: IDeploymentAsset[]): IChecklistItem[] {
    const rv = [
      ...availableDeploymentAssets.map((asset: IDeploymentAsset, idx: number) => ({
        id: idx,
        value: asset,
        isSelected: !selectedDeploymentAssets || !!selectedDeploymentAssets.filter((x) => _.isEqual(x, asset)).length,
      })),
    ];
    return rv;
  }
}
