import {RouterReducerState} from '@ngrx/router-store';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SITE_OVERVIEW_FEATURE_KEY, SiteOverviewState} from './site-overview.reducer';
import {ISiteDetail} from "@mysas/shared/data-access-sites";

export const selectRouter = createFeatureSelector<RouterReducerState>(
  SITE_OVERVIEW_FEATURE_KEY
);

// Lookup the 'SiteOverview' feature state managed by NgRx
export const getSiteOverviewState = createFeatureSelector<SiteOverviewState>(
  SITE_OVERVIEW_FEATURE_KEY
);

export const getSiteDetails = createSelector(
  getSiteOverviewState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ ...rest }): Partial<ISiteDetail> | undefined => rest
);

/** Loading-related selectors */

export const selectLoadingState = createSelector(
  getSiteOverviewState,
  ({ loading }) => loading
);

export const selectLoadingStateSiteDetails = createSelector(
  selectLoadingState,
  ({ details }) => details
);

export const selectLoadingStateLicenseExtension = createSelector(
  selectLoadingState,
  ({ license }) => license
);
