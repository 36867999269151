import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoadingErrorComponent} from '@mysas/portal/orders/ui';
import {
  AlertComponentModule,
  DropdownModule,
  SpinnerComponentModule,
  TabsModule,
  TokenComponentModule
} from '@mysas/portal/shared/ui-layout';
import {
  combinePromises,
  DevIdentifyI18nDirectiveModule,
  getLanguageFileNames,
  scopeLoader
} from '@mysas/shared/util-i18n';
import {Translation, TRANSLOCO_SCOPE, TranslocoModule} from '@ngneat/transloco';
import {TranslocoLocaleModule} from '@ngneat/transloco-locale';
import {AssetPanelComponent} from './asset-panel/asset-panel.component';
import {DeploymentAssetPanelComponent} from './deployment-asset-panel/deployment-asset-panel.component';
import {DeploymentAssetTableComponent} from './deployment-asset-table/deployment-asset-table.component';
import {DownloadsToolbarComponent} from './downloads-toolbar/downloads-toolbar.component';
import {ManageAccessComponent} from './manage-access/manage-access.component';
import {OrderBasicInfoComponent} from './order-basic-info/order-basic-info.component';
import {AssetTypePipeModule} from './pipes';
import {UnableToDownloadComponent} from './unable-to-download/unable-to-download.component';
import {VersionAssetTableComponent} from './version-asset-table/version-asset-table.component';
import {PanelModule} from "@mysas/portal/shared/util-panel-service";

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    TranslocoModule,
    TabsModule,
    FormsModule,
    ReactiveFormsModule,
    DevIdentifyI18nDirectiveModule,
    TokenComponentModule,
    DropdownModule,
    AssetTypePipeModule,
    SpinnerComponentModule,
    AlertComponentModule,
    LoadingErrorComponent,
    TranslocoLocaleModule,
    PanelModule
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'orderOverview',
        loader: scopeLoader((locale: string, root: string) => {
          const langPromises: Promise<Translation>[] = [];
          getLanguageFileNames(locale).forEach((filename) => {
            langPromises.push(
              import(`./assets/${root}/${filename}`).catch((err) =>
                console.error(
                  `error importing language file (${filename}): ${err}`
                )
              )
            );
          });

          return combinePromises(langPromises);
        }),
      },
    },
  ],
  declarations: [
    AssetPanelComponent,
    DeploymentAssetPanelComponent,
    DeploymentAssetTableComponent,
    VersionAssetTableComponent,
    DownloadsToolbarComponent,
    OrderBasicInfoComponent,
    ManageAccessComponent,
    UnableToDownloadComponent,
  ],
  exports: [
    AssetPanelComponent,
    DeploymentAssetPanelComponent,
    DeploymentAssetTableComponent,
    VersionAssetTableComponent,
    DownloadsToolbarComponent,
    OrderBasicInfoComponent,
    ManageAccessComponent,
    UnableToDownloadComponent,
  ],
})
export class UiOrderOverviewModule {}
