import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PanelService} from "./panel.service";

@NgModule({
  imports: [CommonModule],
  providers: [
    PanelService
  ]
})
export class PanelModule {}
