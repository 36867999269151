import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {IErrorPayload, IResponseEnvelope} from '@mysas/shared/data-access-common';
import {ILicense, ISite, ISiteDetail} from '@mysas/shared/data-access-sites';
import {environment} from '@mysas/shared/util-environment';
import {TranslocoService} from '@ngneat/transloco';
import {NGXLogger} from 'ngx-logger';
import {catchError, map, Observable, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SitesService {
  protected apiEndpoint: string;

  private translocoService = inject(TranslocoService);
  logger = inject(NGXLogger);

  constructor(private http: HttpClient) {
    this.apiEndpoint = environment.apiConnectorEndpoint;
  }

  /*public getSitesForCurrentUser(): Observable<ISite[]> {
    return this.http
      .get<IResponseEnvelope<ISite[]>>(`${this.apiEndpoint}/sites/me`)
      .pipe(
        map((response:IResponseEnvelope<ISite[]>) => response.data),
        catchError((err) => {
          this.logger.error(`Error getting site data`, err);
          return throwError(
            () =>
              new Error(
                this.translocoService.translate('couldNotLoadSites.txt')
              )
          );
        })
      );
  }*/

  public getSites(query: string): Observable<ISite[]> {
    return this.http
      .get<IResponseEnvelope<ISite[]>>(`${this.apiEndpoint}/sites/?query=` + query)
      .pipe(
        map((response:IResponseEnvelope<ISite[]>) => response.data),
        catchError((err) => {
          this.logger.error(`Error getting site data`, err);
          return throwError(
            () =>
              new Error(
                this.translocoService.translate('couldNotLoadSites.txt')
              )
          );
        })
      );
  }

  public getSiteDetails(
    tssite: string
  ): Observable<ISiteDetail> {
    return this.http
      .get<IResponseEnvelope<ISiteDetail>>(
        `${this.apiEndpoint}/sites/${tssite}`
      )
      .pipe(
        map((response:IResponseEnvelope<ISiteDetail>) => response.data),
        catchError(this.handleApiError.bind(this))
      );
  }

  public requestLicenseExtension(
    tssite: string,
    releaseGroup: string
  ): Observable<ILicense> {
    return this.http
      .post<IResponseEnvelope<ILicense>>(
        `${this.apiEndpoint}/license/`, {tssite: tssite, releaseGroup: releaseGroup}
      )
      .pipe(
        map((response:IResponseEnvelope<ILicense>) => response.data),
        catchError(this.handleApiError.bind(this)
        )
      );
  }

  private handleApiError(error: unknown): Observable<never> {
    // this.logger.debug(`Evaluating incoming error`, error);
    if (error instanceof HttpErrorResponse) {
      if (error.error?.data) {
        // this is a proper instance of IErrorPayload, pull out end user message
        const payload = error.error as IResponseEnvelope<IErrorPayload>;
        // this.logger.debug(`Extracting error response`, payload);
        return throwError(() => new Error(payload.data.endUserMessage));
      }
      // not an instance of IErrorResponse
      if (error.status >= 500 || error.status === 0) {
        // TODO - calling translate here always returns `this is undefined`
        // const errMessage = this.translocoService.translate('loadingError.txt');
        return throwError(() => {
          const msg = this.translocoService.translate(
            'unableToLoadInformation.txt'
          );
          return new Error(msg);
        });
      }
      return throwError(() => new Error(error.message));
    } else if (error instanceof Error) {
      return throwError(() => new Error(error.message));
    }
    return throwError(() => error);
  }
}
