import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SITES_FEATURE_KEY, sitesAdapter, SitesState} from './sites.reducer';

// Lookup the 'Sites' feature state managed by NgRx
export const getSitesState =
  createFeatureSelector<SitesState>(SITES_FEATURE_KEY);

const { selectAll, selectEntities } = sitesAdapter.getSelectors();

export const getSitesLoaded = createSelector(
  getSitesState,
  (state: SitesState) => state.loaded
);

export const getSitesError = createSelector(
  getSitesState,
  (state: SitesState) => state.error
);

export const getAllSites = createSelector(
  getSitesState,
  (state: SitesState) => (state ? selectAll(state) : [])
);

export const getSitesEntities = createSelector(
  getSitesState,
  (state: SitesState) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getSitesState,
  (state: SitesState) => state.selectedId
);

export const getSelected = createSelector(
  getSitesEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);

export const getSitesSortBy = createSelector(
  getSitesState,
  (state: SitesState) => state.sortBy
);

export const getSitesSortDirection = createSelector(
  getSitesState,
  (state: SitesState) => state.sortDirection
);

export const getSitesFilter = createSelector(
  getSitesState,
  (state: SitesState) => state.filter ?? null
);
