import {createAction, createActionGroup, props} from '@ngrx/store';
import {ILicense, ISiteDetail} from "@mysas/shared/data-access-sites";

export const initSiteOverview = createAction('[SiteOverview Page] Init');

export const LoadSiteDetail = createActionGroup({
  source: 'Site Details',
  events: {
    load: props<{ tssite: string }>(),
    success: props<{ detail: ISiteDetail }>(),
    failure: props<{ error: string }>(),
  },
});

export const LicenseExtension = createActionGroup({
  source: 'Site Extension',
  events: {
    request: props<{ tssite: string, releaseGroup: string }>(),
    success: props<{ license: ILicense }>(),
    failure: props<{ error: string }>(),
  },
});
