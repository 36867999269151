<ng-container *transloco="let t">
  <ng-container
    *ngIf="{
      loadingStateDeploymentAssets: loadingStateDeploymentAssets$ | async,
      error: error$ | async,
      availableDeploymentAssets: availableDeploymentAssets$ | async,
      selectedDeploymentAssets: selectedDeploymentAssets$ | async
    } as obs"
  >
    <div
      class="asset-panel"
      role="dialog"
      aria-describedby="assets-panel-downloads-header"
    >
      <h2 class="asset-panel__title" id="assets-panel-downloads-header">
        {{ t('deploymentAssetsPanel.title') }}
      </h2>
      <div class="dialog__dismiss">
        <button
          #dismissAssetPanelButton
          tabindex="0"
          class="btn btn-standard-no-bd-bg"
          attr.aria-label="{{ t('deploymentAssetsPanelClose.aria') }}"
          (click)="close()"
        ></button>
      </div>

      <!-- success state -->
      <ng-container *ngIf="obs.loadingStateDeploymentAssets?.success">
        <h2 class="deployment-assets-version-header">
          {{
            t('deploymentAssetsPanelVersion.label', {
              cadenceName: data.version.cadenceName,
              cadenceVersion: data.version.cadenceVersion
            })
          }}
        </h2>
        <mysas-deployment-asset-table *ngIf="obs.availableDeploymentAssets"
          [data]="data"
          [availableDeploymentAssets]="obs.availableDeploymentAssets"
          [selectedDeploymentAssets]="obs.selectedDeploymentAssets"
          (saveSubset)="saveSubsetList($event)"
          (closePanel)="close()"
        ></mysas-deployment-asset-table>
      </ng-container>

      <!-- error state -->
      <ng-container *ngIf="obs.loadingStateDeploymentAssets?.error">
        <div class="error-container">
          <!-- <alert state="alert" [dismissable]="false">{{ obs.error }}</alert> -->
          <mysas-loading-error
            [error]="obs.loadingStateDeploymentAssets?.error"
          ></mysas-loading-error>
        </div>
      </ng-container>

      <!-- loading state -->
      <ng-container *ngIf="obs.loadingStateDeploymentAssets?.status === 'LOADING'">
        <div class="loading-container">
          <spinner></spinner>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
