import {ComponentType} from '@angular/cdk/portal';
import {Injectable} from '@angular/core';
import {OrdersService} from '@mysas/portal/shared/data-access-orders';
import {IAddUserToOrder, ICadence, IDeploymentAsset, IDownloadLink} from '@mysas/shared/data-access-orders';
import {Store} from '@ngrx/store';
import {distinctUntilChanged, take} from 'rxjs';
import {ICadenceVersionWithHistory} from '../version-with-history.interface';
import * as OrderOverviewActions from './order-overview.actions';
import {SelectedDeploymentAssetsState} from './order-overview.reducer';

import * as OrderOverviewSelectors from './order-overview.selectors';
import {IDeploymentAssetPanelConfig} from "@mysas/portal/shared/util-panel-service";

@Injectable()
export class OrderOverviewFacade {
  loaded$ = this.store.select(OrderOverviewSelectors.getOrderOverviewLoaded);
  error$ = this.store.select(OrderOverviewSelectors.getOrderOverviewError);
  order$ = this.store.select(OrderOverviewSelectors.getOrderDetails);
  users$ = this.store.select(OrderOverviewSelectors.getOrderUsers);

  // this exposes a concatenated array of _all_ versions across _all_ cadences
  // and uses `distinctUntilChanged` to minimize change detection cycles
  cadenceVersions$ = this.store
    .select(OrderOverviewSelectors.getCadenceVersions)
    .pipe(
      distinctUntilChanged(
        (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
      )
    );

  downloadHistory$ = this.store.select(
    OrderOverviewSelectors.getDownloadHistory
  );

  availableDeploymentAssets$ = this.store.select(
    OrderOverviewSelectors.getAvailableDeploymentAssets
  );

  selectedDeploymentAssets$ = this.store.select(
    OrderOverviewSelectors.getSelectedDeploymentAssets
  );

  addUserRequest$ = this.store.select(
    OrderOverviewSelectors.getAddUserRequestState
  );

  assetPanelState$ = this.store.select(
    OrderOverviewSelectors.getAssetPanelState
  );

  deploymentAssetPanelState$ = this.store.select(
    OrderOverviewSelectors.getDeploymentAssetPanelState
  );

  updatePermissionProcessing$ = this.store.select(
    OrderOverviewSelectors.getUpdateUserPermissionsState
  );

  cadenceDropdownOptions$ = this.store.select(
    OrderOverviewSelectors.selectAvailableCadenceNames
  );
  versionDropdownOptions$ = this.store.select(
    OrderOverviewSelectors.selectAvailableVersionNames
  );
  downloadsFilter$ = this.store.select(
    OrderOverviewSelectors.selectDownloadsFilter
  );
  downloadsFilterState$ = this.store.select(
    OrderOverviewSelectors.selectFiltersState
  );

  loadingStateHistory$ = this.store.select(
    OrderOverviewSelectors.selectLoadingStateOrderHistory
  );
  loadingStateDetails$ = this.store.select(
    OrderOverviewSelectors.selectLoadingStateOrderDetails
  );
  loadingStateUsers$ = this.store.select(
    OrderOverviewSelectors.selectLoadingStateOrderUsers
  );
  loadingStateCadences$ = this.store.select(
    OrderOverviewSelectors.selectLoadingStateOrderCadences
  );
  loadingStateDeploymentAssets$ = this.store.select(
    OrderOverviewSelectors.selectLoadingStateOrderDeploymentAssets
  );

  assetDownloadInProgress$ = this.store.select(
    OrderOverviewSelectors.getAssetDownloadInProgress
  );

  constructor(
    private readonly store: Store,
    private ordersService: OrdersService
  ) {}

  initOrderOverview() {
    this.store.dispatch(OrderOverviewActions.initOrderOverview());
  }

  openAddUserDialog(component: ComponentType<unknown>) {
    this.store.dispatch(OrderOverviewActions.openAddUserDialog({ component }));
  }

  openDeploymentAssetPanel(component: ComponentType<unknown>, config: IDeploymentAssetPanelConfig) {
    this.store.dispatch(OrderOverviewActions.openDeploymentAssetPanel({ component, config }))
  }

  setDeploymentSubset(selectedDeploymentAssets: IDeploymentAsset[], version: ICadenceVersionWithHistory) {
    let id = version.uriName + version.uriVersion;
    this.store.dispatch(OrderOverviewActions.setDeploymentSubset({ id, selectedDeploymentAssets }))
  }

  inviteNewUser(payload: IAddUserToOrder) {
    this.order$.pipe(take(1)).subscribe((order) => {
      this.store.dispatch(
        OrderOverviewActions.addUser({
          payload,
          orderNumber: order?.number as string,
        })
      );
    });
  }

  reinviteUserToOrder(email: string) {
    this.order$.pipe(take(1)).subscribe((order) =>
      this.store.dispatch(
        OrderOverviewActions.ResendInviteActions.inviteRequested({
          orderNumber: order?.number as string,
          payload: { email },
        })
      )
    );
  }

  removeUserFromOrder(email: string) {
    this.order$.pipe(take(1)).subscribe((order) =>
      this.store.dispatch(
        OrderOverviewActions.RemoveUserActions.removeRequested({
          orderNumber: order?.number as string,
          email,
        })
      )
    );
  }

  downloadAssetsSubset(
    version: ICadence,
    assetTypes: {
      license: boolean;
      deploymentAssets: boolean;
      certificates: boolean;
    },
    selectedDeploymentAssets: SelectedDeploymentAssetsState | null
  ) {
    this.store.dispatch(
      OrderOverviewActions.DownloadAssetsActions.downloadRequested({
        assetTypes,
        href: version.assets.downloadLink.href,
        uriName: version.uriName,
        uriVersion: version.uriVersion,
      })
    );
  }

  downloadAssets(
    version: ICadence,
    assetTypes: {
      license: boolean;
      deploymentAssets: boolean;
      certificates: boolean;
    }
  ) {
    this.store.dispatch(
      OrderOverviewActions.DownloadAssetsActions.downloadRequested({
        assetTypes,
        href: version.assets.downloadLink.href,
        uriName: version.uriName,
        uriVersion: version.uriVersion,
      })
    );
  }

  /**
   * This is seperate from downloadAssets since that method does not allow deploymentReadme to
   * be specified. Also, because the target is an HTML file with in-line CSS, browsers will (by default)
   * prevent rendering of the document due to security concerns. This method is written so that the README
   * will be forced to download.
   */
  downloadReadme(doc: IDownloadLink) {
    this.ordersService.downloadDocument(doc).subscribe({
      next: (res) => {
        console.dir(res);
        res.headers.keys();
        const disposition = res.headers.get('Content-Disposition');
        const filename =
          disposition?.split('=').pop() ?? 'deploymentReadme.html';
        const link = document.createElement('a');
        link.style.display = 'none';
        link.download = filename;
        link.href = window.URL.createObjectURL(res.body as Blob);
        link.click();
        link.remove();
      },
    });
  }

  changeDownloadsFilter(filter: string | null) {
    this.store.dispatch(
      OrderOverviewActions.ChangeFilter.updateFilter({ filter })
    );
  }

  changeDownloadsCadence(selectedCadence: string) {
    this.store.dispatch(
      OrderOverviewActions.ChangeFilter.selectCadence({ selectedCadence })
    );
  }

  changeDownloadsVersion(selectedVersion: string) {
    this.store.dispatch(
      OrderOverviewActions.ChangeFilter.selectVersion({ selectedVersion })
    );
  }

  changeDownloadsAssetType(selectedAssetType: string) {
    this.store.dispatch(
      OrderOverviewActions.ChangeFilter.selectAssetType({ selectedAssetType })
    );
  }

  clearDownloadsFilters() {
    this.store.dispatch(OrderOverviewActions.ChangeFilter.clearFilters());
  }

  updateUserPermission(label: string, email: string, role: 'owner' | 'user') {
    this.store.dispatch(
      OrderOverviewActions.ChangeUserPermissionActions.changePermission({
        label,
        email,
        role,
      })
    );
  }
}
